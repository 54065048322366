import { defineStore } from 'pinia';
import { store } from '@/store';
import { storage } from '@/utils/Storage';
import { ACCESS_TOKEN, CURRENT_USER } from '../mutation-types';
import { getUserInfo as getUserInfoApi, login } from '@/api/system/user';
import { ResultEnum } from '@/enums/httpEnum';

export type UserInfoType = {
  id: number;
  avatar: string;
  nickname: string;
  username: string;
  real_name: string;
  super_admin: number;
  user_status: number;
  roles: any[];
  staff: any;
  agent: any;
  wallet: any;
};

export interface IUserState {
  token: string;
  info: UserInfoType;
  code: string;
}

export const useUserStore = defineStore({
  id: 'app-user',
  state: (): IUserState => ({
    token: storage.get(ACCESS_TOKEN, ''),
    info: storage.get(CURRENT_USER, {}),
    code: '',
  }),
  getters: {
    getToken(): string {
      return this.token;
    },

    getUserInfo(): UserInfoType {
      return this.info;
    },
    getCode(): string {
      return this.code;
    },
  },
  actions: {
    setToken(token: string) {
      this.token = token;
    },
    setUserInfo(info: UserInfoType) {
      this.info = info;
    },
    setCode(code: string) {
      this.code = code;
    },
    // 登录
    async login(params: any) {
      const result = await login(params);
      const { data, code }: any = result;
      if (code === ResultEnum.SUCCESS) {
        const ex = 7 * 24 * 60 * 60;
        storage.set(ACCESS_TOKEN, data.token, ex);
        storage.set(CURRENT_USER, data, ex);
        this.setToken(data.token);
        this.setUserInfo(data);
      }
      return result as any;
    },

    // 获取用户信息
    async getInfo() {
      const result = await getUserInfoApi();
      const { data } = result;
      this.setUserInfo(data.user);
      return data;
    },

    // 登出
    async logout() {
      this.setUserInfo({ nickname: '', username: '', avatar: '' } as any);
      storage.remove(ACCESS_TOKEN);
      storage.remove(CURRENT_USER);
      window.location.href = '/';
    },
  },
});

// Need to be used outside the setup
export function useUser() {
  return useUserStore(store);
}
