import { createRouter, createWebHistory } from 'vue-router';
import { ACCESS_TOKEN, CURRENT_USER } from './store/mutation-types';
import { storage } from './utils/Storage';
import { PageEnum } from './enums/pageEnum';
import { useUserStore } from './store/modules/user';

const LOGIN_PATH = PageEnum.BASE_LOGIN;
const whitePathList = [LOGIN_PATH, '/openChatGroup','/order','/orderLogs','/test'];

const routes: any[] = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'login',
    },
  },
  {
    name: '登录',
    path: '/login',
    component: () => import('@/view/login/index.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    name: '个人中心',
    path: '/user',
    component: () => import('@/view/user/index.vue'),
    meta: {
      title: '个人中心',
    },
  },
  {
    children: [
      {
        name: '工单管理',
        path: '/orders',
        component: () => import('@/view/orders/index.vue'),
        meta: {
          title: '工单管理',
        },
      },
      {
        name: '创建工单',
        path: '/orderAdd',
        component: () => import('@/view/orderAdd/index.vue'),
        meta: {
          title: '创建工单',
        },
      },
      {
        name: '工单详情',
        path: '/order',
        component: () => import('@/view/order/index.vue'),
        meta: {
          title: '工单详情',
        },
      },
      {
        name: '工单日志',
        path: '/orderLogs',
        component: () => import('@/view/orderLogs/index.vue'),
        meta: {
          title: '工单日志',
        },
      },
      {
        name: '工单日志新增',
        path: '/orderLogAdd',
        component: () => import('@/view/orderLogAdd/index.vue'),
        meta: {
          title: '工单日志新增',
        },
      },
    ],
  },
  {
    children: [
      {
        name: '合同管理',
        path: '/contracts',
        component: () => import('@/view/contracts/index.vue'),
        meta: {
          title: '合同管理',
        },
      },
      {
        name: '合同详情',
        path: '/contract',
        component: () => import('@/view/contract/index.vue'),
        meta: {
          title: '合同管理',
        },
      },
      {
        name: '合同日志',
        path: '/contractLogs',
        component: () => import('@/view/contractLogs/index.vue'),
        meta: {
          title: '合同日志',
        },
      },
      {
        name: '合同日志新增',
        path: '/contractLogAdd',
        component: () => import('@/view/contractLogAdd/index.vue'),
        meta: {
          title: '合同日志新增',
        },
      },
    ],
  },
  {
    children: [
      {
        name: '客户管理',
        path: '/clients',
        component: () => import('@/view/clients/index.vue'),
        meta: {
          title: '客户管理',
        },
      },
      {
        name: '客户档案',
        path: '/client',
        component: () => import('@/view/client/index.vue'),
        meta: {
          title: '客户档案',
        },
      },
    ],
  },
  {
    name: '打开群聊',
    path: '/openChatGroup',
    component: () => import('@/view/openChatGroup/index.vue'),
    meta: {
      title: '打开群聊',
    },
  },
  // 新增重定向规则
  {
    path: '/',
    redirect: '/user',
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to?.meta?.title;
  if (title) {
    document.title = title as string;
  }
  // 白名单路由
  if (whitePathList.includes(to.path as PageEnum)) {
    next();
    return;
  }

  const token = storage.get(ACCESS_TOKEN);
  const userInfo = storage.get(CURRENT_USER);
  if (!token) {
    if (to.meta.ignoreAuth) {
      next();
      return;
    }
    const redirectData: {
      path: string;
      replace: boolean;
      query?: any;
    } = {
      path: LOGIN_PATH,
      replace: true,
    };
    if (to.path) {
      redirectData.query = {
        ...redirectData.query,
        redirect: to.path,
      };
    }
    next(redirectData);
    return;
  } else if (!userInfo.id) {
    let userStore = useUserStore();
    userStore.getInfo();
  }

  next();
});

export default router;
